$(window).ready(function(){
    initApp();  
});
let clickedcircle= [];
const circleOrder = ['cr1','cr4','cr3','cr2'];
const initApp = ()=>{
    addPulsetoElement("cr1",0);
    addPulsetoElement("cr4",3000);
    addPulsetoElement("cr3",5000);
    addPulsetoElement("cr2",9000);
    addCircleListeners();
}
const addPulsetoElement = (elementID,timeout)=>{
    setTimeout(() => {
        document.getElementById(elementID).className = "pulsebuttons"; 
       
    }, timeout);
}
const addCircleListeners =()=>{
    for(let i = 1; i < 5; i ++){
        createlistener(`cr${i}`);
    }
}
const createlistener = (elementID) =>{
    document.getElementById(elementID).addEventListener('click', (e)=>{
        clickedcircle.push(elementID);
        checkOrder(elementID);

    });
}
const checkOrder = (elementID)=>{
    let check = circleOrder.slice(0, clickedcircle.length);
    check = check.join();
    let newclickedcircle = clickedcircle.join();
    if(check.length === 15 && check === newclickedcircle){
       startLighting();
    }
    else if(check ===  newclickedcircle){
        changeElementColor(elementID);
    }
    else{
        resetCircles();
    }
}

const resetCircles = ()=>{
    for(let i = 1; i < 5; i ++){
      document.getElementById(`cr${i}`).style.textShadow = "unset";
        clickedcircle = [];
    }
}
const changeElementColor = (elementID)=>{
    document.getElementById(elementID).style.textShadow = "0 0 5px #fff200, 0 0 3px #ff9f1a";
}


const startLighting = ()=>{
    setTimeout(() => {
        lightingTitle();
    }, 100);
    for(let i = 1; i < 5; i ++){
     
    
        setTimeout(()=>{
            document.getElementById(`cr${i}`).style.animation = "lighting 1000ms alternate infinite ease";
        },i == 2 ? 0 : i === 3 || i === 4 ? 500 : 1400)  

    }
}
const lightingTitle = ()=>{
    for(let i =1 ; i < 9; i ++){
       setTimeout(() => {
        document.getElementById(`s${i}`).style.animation = "lighting 1000ms alternate infinite ease";
       }, 150 * i);
        
    }
}